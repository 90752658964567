import { fetchCSRFToken, userInfoPath } from '../utils/constant';

export async function getCSRFToken() {
  const output = await fetch(fetchCSRFToken(), {
    method: 'GET',
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        const output = response.json();
        return output;
      }
      throw new Error({ code: response.status, msg: 'Session Invalid' });
    })
    .then((data) => {
      console.log('Response data: ', data);
      localStorage.setItem('csrftoken', data.csrftoken);
      return true;
    })
    .catch((e) => {
      console.error('unable to fetch token', e);
      return false;
    });

  return output;
}

export function getUserInfo() {
  const output = fetch(userInfoPath(), {
    method: 'GET',
    credentials: 'include'
  })
    .then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      throw new Error({ code: response.status, msg: 'Session Invalid' });
    })
    .then((data) => {
      localStorage.removeItem('name');
      localStorage.removeItem('groups');
      localStorage.setItem('name', data.name);
      localStorage.setItem('groups', data.groups);
      return { success: true };
    })
    .catch((e) => {
      console.error('unable to fetch user info', e);
      return { success: false, reason: 'unauthorized' };
    });

  return output;
}
