import { useState } from 'react';
import './App.css';
import Profile from './Profile';

function App() {
  return (
    <div className="App">
      <div className="fixed-top color-nav">
        <img src="header-logo.png" alt="header-logo" className="Header-logo" />
      </div>
      <header>
        <Profile />
      </header>
    </div>
  );
}

export default App;
