import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

import { getUserInfo, getCSRFToken } from './controllers/Authorizer';
import { loginPath } from './utils/constant';


const {
  REACT_APP_LMS_FE,
  REACT_APP_CALC_FE
} = process.env

export default function Profile() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [dev] = useState(window.location.origin.indexOf('dev') > -1 ? true : false);

  useEffect(() => {
    getUserInfo()
      .then((data) => {
        console.log('Data: ', data);
        if (data.success) {
          console.log('Logged In!');
          getCSRFToken().then(() => {
            console.log('token set');
            setLoggedIn(true);
            setAuthorized(true);
          });
        } else {
          window.location.assign(loginPath());
        }
      })
      .catch((err) => {
        console.log('error while logging', err);
      });
  }, [loggedIn, authorized]);

  if (loggedIn) {
    return (
      <>
        <div className="Welcome-text">Hello! Welcome to the Bloom Services Portal.</div>
        <div className="App-header">
          {(localStorage.getItem('groups').indexOf('FinOps') > -1 ||
            localStorage.getItem('groups').indexOf('FinOpsAdmin') > -1) && (
            <a
              className="App-link"
              href={REACT_APP_LMS_FE}
              rel="noopener noreferrer"
            >
              <img src="lms.png" alt="Bloom Loan Management System" />
            </a>
          )}
          <a
            className="App-link"
            href={REACT_APP_CALC_FE}
            rel="noopener noreferrer"
          >
            <img src="calculator.png" alt="Bloom Calculator" />
          </a>
        </div>
      </>
    );
  } else if (!authorized) {
    return <h3>Unable to login!</h3>;
  } else {
    return <h3>Logging using SSO</h3>;
  }
}
