const API_VERSION = '/api/v1';


function apiEndpoint() {
  return process.env.REACT_APP_LMS_API;
}

export function fetchCSRFToken() {
  return `${apiEndpoint()}/csrf/`;
}

export function userInfoPath() {
  const path = `${apiEndpoint()}${API_VERSION}/users/me/`
  return path;
}

export function loginPath() {
  return `${apiEndpoint()}/oauth2/login`;
}
